<template>
  <v-menu offset-y v-bind="$attrs">
    <!-- Selected Application -->
    <template v-slot:activator="{ on, attrs }">
      <v-btn text v-bind="attrs" v-on="on">
        <v-icon :class="spacingClass(spacingDirection.right)">{{
          appModuleIcon
        }}</v-icon>
        <span>{{ appModuleTitle }}</span>
        <v-icon :class="spacingClass(spacingDirection.left)">{{
          iconChevronDown
        }}</v-icon>
      </v-btn>
    </template>

    <!-- Application modules-->
    <base-nav-list>
      <base-menu-item
        v-for="module in appModules"
        :key="module.title"
        :disabled="!canNavigateToAppModule(module.name)"
        :to="buildAppModuleRoute(module.name)"
      >
        <template v-slot:iconName>
          {{ module.icon }}
        </template>
        {{ module.title }}
      </base-menu-item>
    </base-nav-list>
  </v-menu>
</template>

<script>
// model
import { iconChevronDown } from "@/design/icon/iconConst";

// mixins
import { reactiveMixin } from "@/mixins/shared/reactive/reactiveMixin";
import { appModuleNavigatable } from "@/mixins/shared/navigatable/appModuleNavigatable";
import { menuItemSettingsMixin } from "@/mixins/shared/base/settings/menuItemSettingsMixin";
import { spaceableMixin } from "@/mixins/shared/space/spaceableMixin";

export default {
  inheritAttrs: false,
  name: "AppMenu",
  mixins: [
    reactiveMixin,
    appModuleNavigatable,
    menuItemSettingsMixin,
    spaceableMixin
  ],
  components: {
    BaseNavList: () => import("@/components/shared/base/BaseNavList"),
    BaseMenuItem: () => import("@/components/shared/base/BaseMenuItem")
  },
  computed: {
    iconChevronDown: () => {
      return iconChevronDown;
    }
  }
};
</script>
